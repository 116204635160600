import React from "react";
import "../../styles/desktop/listheader.css";

function ListHeader() {
  return (
    <div>
      <div class="content">
        <h2 class="text_shadows">Services</h2>
      </div>
    </div>
  );
}

export default ListHeader;
