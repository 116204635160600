import React from "react";
import "../../styles/mobile/mAbout.css";
import Card from "./Card";

const About = () => {
  return (
    <div id="about" className="mobile-about-container">
      <Card />
    </div>
  );
};

export default About;
